import { initApolloClient } from '@app/config/apollo/apolloActions'

import { authStore } from '../store/authStore'
import { addUnauthorizedHandlerToAxios } from './axiosActions'

export const initAuth = () => {
  addUnauthorizedHandlerToAxios(getAccessToken, logout)
  initApolloClient()
}

export const login = () => {
  const kc = authStore.useStore.getState().keycloak

  if (!kc) {
    throw new Error('KeyCloak not yet initialized')
  }

  return kc.login()
}

export const logout = () => {
  const kc = authStore.useStore.getState().keycloak

  if (!kc) {
    throw new Error('KeyCloak not yet initialized')
  }

  return kc.logout({ redirectUri: window.location.origin })
}

export const getAccessToken = async (): Promise<string | undefined> => {
  const kc = authStore.useStore.getState().keycloak

  if (!kc) {
    throw new Error('KeyCloak not yet initialized')
  }

  await kc.updateToken(30)

  return kc.token
}

export const redirectToAccountManagement = async () => {
  const kc = authStore.useStore.getState().keycloak

  if (!kc) {
    throw new Error('KeyCloak not yet initialized')
  }

  await kc.accountManagement()
}

export const getKeyCloak = () => {
  const kc = authStore.useStore.getState().keycloak

  return kc
}
